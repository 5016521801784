@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

#root {
  background: radial-gradient(circle, rgba(65, 65, 65, 0.3) 0%, rgba(214,214,214,1) 100%);
}

.tp-dfwv {
  z-index: 9999;
}

.main-canvas {
  width: 100% !important;
  /*touch-action: auto !important;*/
  /*pointer-events: all !important;*/

}

@media only screen and (min-width: 1181px) {
  .main-canvas {
    width: 80% !important;
    /*touch-action: none !important;*/
    /*pointer-events: all !important;*/
  }
}
